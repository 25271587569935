export const convertStatusToText = (orderStatus) => {
  switch (orderStatus) {
    case 0:
      return "PreOrden";
    case 1:
      return "Orden Aceptada";
    case 2:
      return "Central Tico Compramos";
    case 3:
      return "Enviado";
    case 4:
      return "Entregado";
    case -1:
      return "Cancelado";
    default:
      return "Error";
  }
};

export const statusColors = {
  0: "#ffc107", // PreOrden (amarillo)
  1: "#1976d2", // Orden Aceptada (azul)
  2: "#f44336", // Central Tico Compramos (rojo)
  3: "#4caf50", // Enviado (verde)
  4: "#673ab7", // Entregado (morado)
  "-1": "#9e9e9e", // Cancelado (gris)
};
