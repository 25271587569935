import React, { useState, useContext } from "react";
import { auth, db } from "../firebaseConfig";
import { doc, getDoc } from "firebase/firestore"; // Importa las funciones de Firestore
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  InputAdornment,
} from "@mui/material";
import { Email, Lock } from "@mui/icons-material";
import ComercioContext from "../ComercioContext"; // Cambia './' a '../'

const LoginContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  borderRadius: theme.shape.borderRadius,
}));

const CustomButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(1.5),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const { setComercioId } = useContext(ComercioContext);
  const { setTipoComercio } = useContext(ComercioContext);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      console.log("entrandooooo");

      console.time("signIn");
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      console.timeEnd("signIn");
      const user = userCredential.user;

      // Recuperar el ID del comercio de Firestore
      const docRef = doc(db, "Server", user.uid); // Obtiene la instancia de Firestore desde auth
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const comercioId = docSnap.data().uidcomercio;
        const tipoComercio = docSnap.data().comercio;

        console.log(comercioId);
        console.log(tipoComercio);

        setComercioId(comercioId);
        setTipoComercio(tipoComercio);
        navigate(`/dashboard/${comercioId}`);
      } else {
        setError("No se encontró el comercio asociado al usuario.");
      }
    } catch (error) {
      console.error("Error al iniciar sesión:", error);

      // Manejo de errores más detallado (ejemplo)
      if (error.code === "auth/wrong-password") {
        setError("Contraseña incorrecta.");
      } else if (error.code === "auth/user-not-found") {
        setError("Usuario no encontrado.");
      } else if (error.code === "permission-denied") {
        // Error de permiso en Firestore
        setError("No tienes permiso para acceder a este comercio.");
      } else {
        setError("Error al iniciar sesión.");
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f5f5f5",
      }}
    >
      <LoginContainer elevation={3}>
        <Typography variant="h4" gutterBottom>
          Inicio de Sesión
        </Typography>
        <Box
          component="form"
          onSubmit={handleLogin}
          sx={{ mt: 2, width: "100%" }}
        >
          <TextField
            label="Correo Electrónico"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Contraseña"
            type="password"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock />
                </InputAdornment>
              ),
            }}
          />
          {error && (
            <Typography color="error" sx={{ mt: 1 }}>
              {error}
            </Typography>
          )}
          <CustomButton type="submit" fullWidth variant="contained">
            Iniciar Sesión
          </CustomButton>
        </Box>
      </LoginContainer>
    </Box>
  );
}

export default Login;
