// src/App.js
import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Dashboard from "./components/Dashboard";
import Products from "./components/Products";
import ShippingPrices from "./components/ShippingPrices";
import CategoriesList from "./components/CategoriesList";

import Orders from "./components/Orders";
import Customers from "./components/Customers";
import StoreSettings from "./components/StoreSettings";
import Login from "./components/Login";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useState, useEffect } from "react";
import ComercioContext from "./ComercioContext"; // Para acceder al contexto
import { ComercioProvider } from "./ComercioContext"; // Para proporcionar el contexto
import RidersManagement from "./components/Shippers";

const theme = createTheme();

function App() {
  const [user, setUser] = useState(null);
  const auth = getAuth();
  const [isLoading, setIsLoading] = useState(true); // Estado de carga
  const { comercioId, setComercioId } = useContext(ComercioContext);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        setComercioId(comercioId);
        setIsLoading(false); // Actualizar el estado de carga
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, [auth, user]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ComercioProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/dashboard/:id"
              element={user ? <Dashboard /> : <Navigate to="/login" />}
            />
            <Route
              path="/products/:id"
              element={user ? <Products /> : <Navigate to="/login" />}
            />
            <Route
              path="/shippingprices/:id"
              element={user ? <ShippingPrices /> : <Navigate to="/login" />}
            />
            <Route
              path="/shippers/:id"
              element={user ? <RidersManagement /> : <Navigate to="/login" />}
            />
            <Route
              path="/categorieslist/:id"
              element={user ? <CategoriesList /> : <Navigate to="/login" />}
            />
          </Routes>
        </Router>
      </ComercioProvider>
    </ThemeProvider>
  );
}

export default App;
