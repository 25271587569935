import React, { useState, useEffect } from "react";
import { db, functions } from "../firebaseConfig";
import {
  collection,
  query,
  doc,
  updateDoc,
  where,
  onSnapshot,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions"; // Importar httpsCallable
import {
  TextField,
  Button,
  Typography,
  Card,
  CardContent,
  CardActions,
  Grid,
  CircularProgress,
  IconButton,
  AppBar,
  Toolbar,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  CssBaseline,
} from "@mui/material";
import styled from "@emotion/styled";
import { useParams, Link, useLocation } from "react-router-dom";
import {
  Dashboard as DashboardIcon,
  Store as StoreIcon,
  LocalShipping as LocalShippingIcon,
  Category as CategoryIcon,
} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import MenuIcon from "@mui/icons-material/Menu";
import Switch from "@mui/material/Switch";

const drawerWidth = 240;

const NavigationDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    boxSizing: "border-box",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

const NavigationItem = styled(ListItem)(({ theme, isActive }) => ({
  backgroundColor: isActive ? theme.palette.secondary.dark : "transparent",
  color: isActive ? theme.palette.common.white : theme.palette.common.white,
  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const NavigationText = styled(ListItemText)({
  textAlign: "center",
});

const Root = styled.div`
  display: flex;
  height: 100vh;
`;

const AppBarStyled = styled(AppBar)`
  z-index: 1201; // Ensure AppBar is above Drawer
`;

const Main = styled.main(({ open }) => ({
  flexGrow: 1,
  padding: 24,
  backgroundColor: "#fafafa",
  minHeight: "100vh",
  marginLeft: open ? drawerWidth : 0,
  transition: "margin-left 0.3s",
}));

const CardStyled = styled(Card)`
  min-width: 275px;
  margin-bottom: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  background-color: #ffffff;
  transition: transform 0.3s, box-shadow 0.3s;
  &:hover {
    transform: scale(1.02);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 24px;
  background-color: #ffffff;
  padding: 24px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
`;

const ButtonStyled = styled(Button)`
  align-self: flex-start;
  background-color: #007bff;
  color: #ffffff;
  &:hover {
    background-color: #0056b3;
  }
`;

const Title = styled(Typography)`
  color: #333333;
  font-weight: 600;
`;

const SubTitle = styled(Typography)`
  color: #555555;
`;

const IconButtonStyled = styled(IconButton)`
  color: #007bff;
  &:hover {
    color: #0056b3;
  }
`;

const Shippers = () => {
  const [shippers, setShippers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newShipper, setNewShipper] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    active: true, // Valor booleano añadido
    conductorcomercio: true,
  });
  const { id: comercioId } = useParams();
  const [open, setOpen] = useState(true);

  const location = useLocation();

  useEffect(() => {
    const q = query(
      collection(db, "motorizados"),
      where("uidcomercio", "==", comercioId)
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const shippersData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setShippers(shippersData);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [comercioId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewShipper({
      ...newShipper,
      [name]: value,
    });
  };

  const handleAddShipper = () => {
    const { name, email, phone, password, active, conductorcomercio } =
      newShipper;

    if (name && email && phone && password) {
      const createMotorizado = httpsCallable(functions, "createMotorizado"); // Usa httpsCallable
      createMotorizado({
        name,
        email,
        phone,
        password,
        active,
        conductorcomercio,
        uidcomercio: comercioId,
      })
        .then((result) => {
          console.log("Repartidor agregado exitosamente:", result);
          setNewShipper({ name: "", email: "", phone: "", password: "" });
        })
        .catch((error) => {
          console.error("Error al agregar repartidor:", error);
        });
    }
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const isActiveRoute = (route) => {
    return location.pathname === route;
  };

  const handleToggleActive = (id, currentStatus) => {
    // Actualizar el estado 'active' en Firestore
    const shipperRef = doc(db, "motorizados", id);
    updateDoc(shipperRef, { active: !currentStatus })
      .then(() => {
        console.log(`Estado del repartidor ${id} actualizado.`);
      })
      .catch((error) => {
        console.error("Error al actualizar el estado:", error);
      });
  };
  return (
    <Root>
      <CssBaseline />
      <AppBarStyled position="fixed">
        <Toolbar>
          <IconButton color="inherit" edge="start" onClick={handleDrawerToggle}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Gestión de Repartidores
          </Typography>
        </Toolbar>
      </AppBarStyled>
      <NavigationDrawer
        variant="persistent"
        open={open}
        sx={{
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <List>
          <NavigationItem
            button
            component={Link}
            to={`/dashboard/${comercioId}`}
            isActive={isActiveRoute(`/dashboard/${comercioId}`)}
          >
            <ListItemIcon>
              <DashboardIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <NavigationText primary="Panel de ordenes" />
          </NavigationItem>

          <NavigationItem
            button
            component={Link}
            to={`/categorieslist/${comercioId}`}
            isActive={isActiveRoute(`/categorieslist/${comercioId}`)}
          >
            <ListItemIcon>
              <CategoryIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <NavigationText primary="Categorias" />
          </NavigationItem>

          <NavigationItem
            button
            component={Link}
            to={`/products/${comercioId}`}
            isActive={isActiveRoute(`/products/${comercioId}`)}
          >
            <ListItemIcon>
              <StoreIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <NavigationText primary="Productos" />
          </NavigationItem>

          <NavigationItem
            button
            component={Link}
            to={`/shippers/${comercioId}`}
            isActive={isActiveRoute(`/shippers/${comercioId}`)}
          >
            <ListItemIcon>
              <LocalShippingIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <NavigationText primary="Motorizados" />
          </NavigationItem>

          <NavigationItem
            button
            component={Link}
            to={`/shippingprices/${comercioId}`}
            isActive={isActiveRoute(`/shippingprices/${comercioId}`)}
          >
            <ListItemIcon>
              <LocalShippingIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <NavigationText primary="Precios de Envio" />
          </NavigationItem>
        </List>
      </NavigationDrawer>

      <Main open={open}>
        <Title variant="h4" gutterBottom>
          Gestionar Repartidores
        </Title>
        <Form noValidate autoComplete="off">
          <TextField
            label="Nombre"
            name="name"
            value={newShipper.name}
            onChange={handleInputChange}
            required
            variant="outlined"
            fullWidth
          />
          <TextField
            label="Correo Electrónico"
            name="email"
            value={newShipper.email}
            onChange={handleInputChange}
            required
            variant="outlined"
            fullWidth
          />
          <TextField
            label="Teléfono"
            name="phone"
            value={newShipper.phone}
            onChange={handleInputChange}
            required
            variant="outlined"
            fullWidth
          />
          <TextField
            label="Contraseña"
            name="password"
            type="password"
            value={newShipper.password}
            onChange={handleInputChange}
            required
            variant="outlined"
            fullWidth
          />
          <ButtonStyled
            variant="contained"
            onClick={handleAddShipper}
            startIcon={<AddIcon />}
          >
            Agregar Repartidor
          </ButtonStyled>
        </Form>
        {loading ? (
          <CircularProgress color="primary" />
        ) : (
          <Grid container spacing={2}>
            {shippers.map((shipper) => (
              <Grid item key={shipper.id} xs={12} sm={6} md={4}>
                <CardStyled>
                  <CardContent>
                    <Typography variant="h6" component="h3" gutterBottom>
                      {shipper.name}
                    </Typography>
                    <SubTitle color="textSecondary">{shipper.email}</SubTitle>
                    <SubTitle color="textSecondary">{shipper.phone}</SubTitle>
                  </CardContent>
                  <CardActions>
                    <IconButtonStyled>
                      <EditIcon />
                    </IconButtonStyled>
                    <Switch
                      checked={shipper.active}
                      onChange={() =>
                        handleToggleActive(shipper.id, shipper.active)
                      }
                      color="primary"
                    />
                    {/* Agrega más botones o acciones si es necesario */}
                  </CardActions>
                </CardStyled>
              </Grid>
            ))}
          </Grid>
        )}
      </Main>
    </Root>
  );
};

export default Shippers;
