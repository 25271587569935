import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Grid,
  Paper,
  Typography,
  IconButton,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  Toolbar,
  AppBar,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { db } from "../firebaseConfig";
import {
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
  query,
  where,
} from "firebase/firestore";
import { useParams, Link, useLocation } from "react-router-dom";
import {
  Dashboard as DashboardIcon,
  Store as StoreIcon,
  LocalShipping as LocalShippingIcon,
} from "@mui/icons-material";
import CategoryIcon from "@mui/icons-material/Category";
import { styled } from "@mui/system";
import MenuIcon from "@mui/icons-material/Menu";

const drawerWidth = 240;

const NavigationDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    boxSizing: "border-box",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

const NavigationItem = styled(ListItem)(({ theme, isActive }) => ({
  backgroundColor: isActive ? theme.palette.secondary.dark : "transparent",
  color: isActive ? theme.palette.common.white : theme.palette.common.white,
  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const NavigationText = styled(ListItemText)({
  textAlign: "center",
});

const AppBarStyled = styled(AppBar)`
  z-index: 1201; // Ensure AppBar is above Drawer
`;

const Main = styled(Box)(({ open }) => ({
  flexGrow: 1,
  padding: 24,
  backgroundColor: "#fafafa",
  minHeight: "100vh",
  marginLeft: open ? drawerWidth : 0,
  transition: "margin-left 0.3s",
}));

const ShippingPrices = () => {
  const [shippingPrices, setShippingPrices] = useState([]);
  const [rangeStart, setRangeStart] = useState("");
  const [rangeEnd, setRangeEnd] = useState("");
  const [price, setPrice] = useState("");
  const { id: comercioId } = useParams();
  const location = useLocation();
  const [open, setOpen] = useState(true); // Ensure drawer is open by default

  useEffect(() => {
    const fetchShippingPrices = async () => {
      const q = query(
        collection(db, "shippingPrices"),
        where("comercioId", "==", comercioId)
      );
      const querySnapshot = await getDocs(q);
      const prices = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setShippingPrices(prices);
    };

    fetchShippingPrices();
  }, [comercioId]);

  const handleAddRange = async () => {
    if (rangeStart !== "" && rangeEnd !== "" && price !== "") {
      const newRange = { rangeStart, rangeEnd, price, comercioId };
      const docRef = await addDoc(collection(db, "shippingPrices"), newRange);
      setShippingPrices([...shippingPrices, { id: docRef.id, ...newRange }]);
      setRangeStart("");
      setRangeEnd("");
      setPrice("");
    }
  };

  const handleDeleteRange = async (id) => {
    await deleteDoc(doc(db, "shippingPrices", id));
    setShippingPrices(shippingPrices.filter((range) => range.id !== id));
  };

  const isActiveRoute = (route) => {
    return location.pathname === route;
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBarStyled position="fixed">
        <Toolbar>
          <IconButton color="inherit" edge="start" onClick={handleDrawerToggle}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Precios de Envío
          </Typography>
        </Toolbar>
      </AppBarStyled>
      <NavigationDrawer
        variant="persistent"
        open={open}
        sx={{
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <List>
          <NavigationItem
            button
            component={Link}
            to={`/dashboard/${comercioId}`}
            isActive={isActiveRoute(`/dashboard/${comercioId}`)}
          >
            <ListItemIcon>
              <DashboardIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <NavigationText primary="Panel de ordenes" />
          </NavigationItem>

          <NavigationItem
            button
            component={Link}
            to={`/categorieslist/${comercioId}`}
            isActive={isActiveRoute(`/categorieslist/${comercioId}`)}
          >
            <ListItemIcon>
              <CategoryIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <NavigationText primary="Categorias" />
          </NavigationItem>

          <NavigationItem
            button
            component={Link}
            to={`/products/${comercioId}`}
            isActive={isActiveRoute(`/products/${comercioId}`)}
          >
            <ListItemIcon>
              <StoreIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <NavigationText primary="Productos" />
          </NavigationItem>

          <NavigationItem
            button
            component={Link}
            to={`/shippers/${comercioId}`}
            isActive={isActiveRoute(`/shippers/${comercioId}`)}
          >
            <ListItemIcon>
              <LocalShippingIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <NavigationText primary="Motorizados" />
          </NavigationItem>

          <NavigationItem
            button
            component={Link}
            to={`/shippingprices/${comercioId}`}
            isActive={isActiveRoute(`/shippingprices/${comercioId}`)}
          >
            <ListItemIcon>
              <LocalShippingIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <NavigationText primary="Precios de Envio" />
          </NavigationItem>
        </List>
      </NavigationDrawer>
      <Main open={open}>
        <Toolbar />
        <Paper elevation={3} sx={{ padding: 3 }}>
          <Typography variant="h6" gutterBottom>
            Precios de Envío por Distancia
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextField
                label="Desde (km)"
                value={rangeStart}
                onChange={(e) => setRangeStart(e.target.value)}
                type="number"
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Hasta (km)"
                value={rangeEnd}
                onChange={(e) => setRangeEnd(e.target.value)}
                type="number"
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Precio (₡)"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                type="number"
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Add />}
                onClick={handleAddRange}
                fullWidth
                sx={{ height: "100%" }}
              >
                Agregar
              </Button>
            </Grid>
          </Grid>
          <Box mt={4}>
            {shippingPrices.map((range) => (
              <Paper
                key={range.id}
                elevation={2}
                sx={{
                  padding: 2,
                  marginBottom: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography>
                  {range.rangeStart} km - {range.rangeEnd} km: ₡{range.price}
                </Typography>
                <IconButton
                  onClick={() => handleDeleteRange(range.id)}
                  color="secondary"
                >
                  <Delete />
                </IconButton>
              </Paper>
            ))}
          </Box>
        </Paper>
      </Main>
    </Box>
  );
};

export default ShippingPrices;
