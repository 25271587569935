// CategoriesList.js
import React, { useState, useEffect, useContext } from "react";
import { db, storage } from "../firebaseConfig"; // Asegúrate de importar tu configuración de Firebase
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

import {
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Box,
  Toolbar,
  AppBar,
  Typography,
  Drawer,
  ListItemIcon,
  CssBaseline,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { styled } from "@mui/system";

import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import CategoryIcon from "@mui/icons-material/Category"; // Importa el icono aquí

import { useParams, Link, useLocation } from "react-router-dom";
import ComercioContext from "../ComercioContext";
import {
  Dashboard as DashboardIcon,
  Store as StoreIcon,
  LocalShipping as LocalShippingIcon,
} from "@mui/icons-material";

const drawerWidth = 240;

const NavigationItem = styled(ListItem)(({ theme, isActive }) => ({
  backgroundColor: isActive ? theme.palette.secondary.dark : "transparent",
  color: isActive ? theme.palette.common.white : theme.palette.common.white,
  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const NavigationText = styled(ListItemText)({
  textAlign: "center",
});

const NavigationDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    boxSizing: "border-box",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

const CategoriesList = () => {
  const [categories, setCategories] = useState([]);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [newCategoryActive, setNewCategoryActive] = useState(true);
  const [newCategoryImage, setNewCategoryImage] = useState(null);

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [categoryToEdit, setCategoryToEdit] = useState(null);

  const [editCategoryName, setEditCategoryName] = useState("");
  const [editCategoryActive, setEditCategoryActive] = useState(true);
  const [editCategoryImage, setEditCategoryImage] = useState(null);

  const { id: comercioId } = useParams(); // Obtener comercioId desde los parámetros de la ruta
  const { tipoComercio } = useContext(ComercioContext); // Obtener tipoComercio del contexto
  const location = useLocation();

  const fetchCategories = async () => {
    const categoriesRef = collection(
      db,
      `Ciudades/Ciudad Quesada/${tipoComercio}/${comercioId}/Category`
    );
    const querySnapshot = await getDocs(categoriesRef);
    const categoriesList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setCategories(categoriesList);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleAddCategory = async () => {
    if (newCategoryName.trim() !== "") {
      let imageUrl = "";
      if (newCategoryImage) {
        const imageRef = ref(
          storage,
          `categories/${newCategoryName}_${comercioId}`
        );
        await uploadBytes(imageRef, newCategoryImage);
        await new Promise((resolve) => setTimeout(resolve, 5000)); // 5 segundos

        imageUrl = await getResizedImageUrl(imageRef.name, 600, 800); // Aquí obtenemos la URL de la imagen redimensionada
      }
      await addDoc(
        collection(
          db,
          `Ciudades/Ciudad Quesada/${tipoComercio}/${comercioId}/Category`
        ),
        {
          name: newCategoryName,
          active: newCategoryActive,
          image: imageUrl,
        }
      );
      setNewCategoryName("");
      setNewCategoryActive(true);
      setNewCategoryImage(null);
      fetchCategories();
    }
  };

  const handleEditCategory = async () => {
    if (editCategoryName.trim() !== "") {
      let imageUrl = categoryToEdit.image || "";
      if (editCategoryImage) {
        const imageRef = ref(
          storage,
          `categories/${editCategoryName}_${comercioId}`
        );
        await uploadBytes(imageRef, editCategoryImage);
        await new Promise((resolve) => setTimeout(resolve, 5000)); // 5 segundos

        imageUrl = await getResizedImageUrl(imageRef.name, 600, 800); // Aquí obtenemos la URL de la imagen redimensionada
      }
      await updateDoc(
        doc(
          db,
          `Ciudades/Ciudad Quesada/${tipoComercio}/${comercioId}/Category`,
          categoryToEdit.id
        ),
        {
          name: editCategoryName,
          active: editCategoryActive,
          image: imageUrl,
        }
      );
      setEditDialogOpen(false);
      fetchCategories();
    }
  };

  const getResizedImageUrl = async (fileName, width, height) => {
    const resizedFileName = `${fileName}_${width}x${height}`;
    const resizedFileRef = ref(storage, `categories/${resizedFileName}`);
    return await getDownloadURL(resizedFileRef);
  };

  const openEditDialog = (category) => {
    setCategoryToEdit(category);
    setEditCategoryName(category.name);
    setEditDialogOpen(true);
  };

  const handleImageChange = (event, setImage) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const isActiveRoute = (route) => {
    return location.pathname === route;
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            Panel de Ordenes
          </Typography>
        </Toolbar>
      </AppBar>
      <NavigationDrawer
        variant="permanent"
        sx={{
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <List>
          <NavigationItem
            button
            component={Link}
            to={`/dashboard/${comercioId}`}
            isActive={isActiveRoute(`/dashboard/${comercioId}`)}
          >
            <ListItemIcon>
              <DashboardIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <NavigationText primary="Panel de ordenes" />
          </NavigationItem>

          <NavigationItem
            button
            component={Link}
            to={`/categorieslist/${comercioId}`}
            isActive={isActiveRoute(`/categorieslist/${comercioId}`)}
          >
            <ListItemIcon>
              <CategoryIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <NavigationText primary="Categorias" />
          </NavigationItem>

          <NavigationItem
            button
            component={Link}
            to={`/products/${comercioId}`}
            isActive={isActiveRoute(`/products/${comercioId}`)}
          >
            <ListItemIcon>
              <StoreIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <NavigationText primary="Productos" />
          </NavigationItem>

          <NavigationItem
            button
            component={Link}
            to={`/shippingprices/${comercioId}`}
            isActive={isActiveRoute(`/shippingprices/${comercioId}`)}
          >
            <ListItemIcon>
              <LocalShippingIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <NavigationText primary="Precios de Envio" />
          </NavigationItem>
        </List>
      </NavigationDrawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          marginLeft: `${drawerWidth}px`,
          marginTop: "64px", // Height of the AppBar
        }}
      >
        <Toolbar />
        <Paper elevation={3} sx={{ padding: 2 }}>
          <Typography variant="h4" gutterBottom>
            Categorías
          </Typography>
          <TextField
            label="Nueva Categoría"
            value={newCategoryName}
            onChange={(e) => setNewCategoryName(e.target.value)}
            fullWidth
            margin="normal"
          />
          <FormControlLabel
            control={
              <Switch
                checked={newCategoryActive}
                onChange={(e) => setNewCategoryActive(e.target.checked)}
                color="primary"
              />
            }
            label="Activo"
            sx={{ marginBottom: 2 }}
          />
          <Button
            variant="contained"
            component="label"
            sx={{ marginBottom: 2 }}
            fullWidth
          >
            Subir Imagen
            <input
              type="file"
              onChange={(e) => handleImageChange(e, setNewCategoryImage)}
              hidden
            />
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAddCategory}
            sx={{ marginBottom: 2 }}
            fullWidth
          >
            Agregar Categoría
          </Button>
          <List>
            {categories.map((category) => (
              <ListItem key={category.id} sx={{ marginBottom: 1 }}>
                <ListItemText primary={category.name} />
                <IconButton edge="end" onClick={() => openEditDialog(category)}>
                  <EditIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
          <Dialog
            open={editDialogOpen}
            onClose={() => setEditDialogOpen(false)}
          >
            <DialogTitle>Editar Categoría</DialogTitle>
            <DialogContent>
              <TextField
                label="Nombre de la Categoría"
                value={editCategoryName}
                onChange={(e) => setEditCategoryName(e.target.value)}
                fullWidth
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={editCategoryActive}
                    onChange={(e) => setEditCategoryActive(e.target.checked)}
                    color="primary"
                  />
                }
                label="Activo"
                sx={{ marginTop: 2 }}
              />
              <Button
                variant="contained"
                component="label"
                sx={{ marginBottom: 2 }}
                fullWidth
              >
                Subir Imagen
                <input
                  type="file"
                  onChange={(e) => handleImageChange(e, setEditCategoryImage)}
                  hidden
                />
              </Button>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setEditDialogOpen(false)} color="primary">
                Cancelar
              </Button>
              <Button onClick={handleEditCategory} color="primary">
                Guardar
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </Box>
    </Box>
  );
};

export default CategoriesList;
