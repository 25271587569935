// src/ComercioContext.js

import React, { createContext, useState } from "react";

const ComercioContext = createContext({
  tipoComercio: "",
  comercioId: null,
  setComercioId: () => {}, // Definimos una función vacía por defecto
  setTipoComercio: () => {}, // Agregamos setTipoComercio al contexto
});

export const ComercioProvider = ({ children }) => {
  const [tipoComercio, setTipoComercioState] = useState("");
  const [comercioId, setComercioIdState] = useState(null); // Estado interno de comercioId

  // Función para actualizar comercioId y setComercioId en el contexto
  const setComercioId = (id) => {
    setComercioIdState(id);
  };

  const setTipoComercio = (tipo) => {
    setTipoComercioState(tipo);
  };

  return (
    <ComercioContext.Provider
      value={{
        tipoComercio,
        comercioId,
        setComercioId, // Pasamos la función setComercioId al contexto
        setTipoComercio,
      }}
    >
      {children}
    </ComercioContext.Provider>
  );
};

export default ComercioContext;
